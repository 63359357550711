<template>
  <div style="margin-top: 20px;">
  <el-button type="primary" style="margin-bottom: 20px;" size="small" @click="$router.back()"
        >返 回</el-button
      >
  <el-card>
    <div slot="header" class="clearfix" style="display: flex;">
      <el-image :src="require('@/assets/images/problemList/标签@2x.png')" style="width: 24px; height: 24px"></el-image>
      <span style="color: #00957e">{{ $route.params.other_id && $route.params.other_id !== '0' ? '编辑作业' : '添加作业' }}</span>
    </div>
    <form-create ref="form" v-model="formData" :inline="false" label-width="130px" :label-position="'right'" :components="components" @update="handleFormUpdate"
                 :disabled="!currentPower"
    ></form-create>

    <el-button-group style="padding-top: 15px;display: flex;
  justify-content: flex-end;">
      <el-button type="primary" :disabled="!currentPower || loading"  @click="submitForm()">保存</el-button>
    </el-button-group>
  </el-card>
</div>

</template>

<script>
export default {
  props:['priexRoute','course_id','other_id','blongTitle'],
  data(){
    var _this = this;
    var blongTitle = this.blongTitle || '教案'
    return {
      formData:{},
      courseInfo:{},
      loading:false,
      flag:false,
      components:[
        {name:'el-row' ,components:[
            {name:'el-col',components:[
                // {name:'form-item',props:{prop:'user_id',required:true,disabled:true,label:'创建人', type:'select',data:{ url:'/xapi/user/teachList', labelOptions:{value:'id',label:'showname'} }}},
                {name:'form-item',props:{prop:'user_id',required:true,disabled:true,label:'创建人', type:'select',data:{ url:'/xapi/user.user/teachList', labelOptions:{value:'id',label:'showname'} }}},
              ]},
            {name:'el-col',props:{span:12},components:[
                {name:'form-item',props:{prop:'is_open',required:true,label:'公开程度',type:'radio',data:[{label:'公开',value:1},{label:'私人',value:2},{label:'团队',value:0}]}
                ,callback(c){
                    c.hidden = _this.priexRoute === 'teachPlanCourse';
                  }},
              ]}
          ]
        },
        {name:'form-item',condition:'{{is_open}} == 0',
        props:{prop:'group_id',label:'选择团队', type:'select',
        multiple:true,data:{ url:'/xapi/user.group/list',params:{type:1}, labelOptions:{value:'id',label:'name'} 
        }}
          ,callback(c){
                    c.hidden = _this.priexRoute === 'teachPlanCourse';
                  },
        },
        
        
        {name:'form-item',props:{prop:'course_id',label:'选择课程',limit:1, type:'data',showKey:'course_name',
            dialog:{
              width:'60%',
              url:'xapi/course.course/courseMix',
              params:{},
              where:{fields:{id:'o_id',course_name:'course_name',unit_name:'unit_name',name:'name',create_time:'create_time'}},
            },
            whereColumns:[
              {name:'form-item',props:{prop:'keyword',label:'关键字',placeholder:'请输入课程名称',style:{width:'550px'}}},
            ],
            columns:[
              {prop:'id',label:'编号',width:'80' , align:'center'},
              {prop:'course_name',label:'课程名称', align:'center'},
            ],
          },callback(c){
            c.props.dialog.params.is_plan = _this.priexRoute === 'teachPlanCourse'?1:0;
            // c.props.required = _this.formData.id > 0?false:true;
           c.hidden = _this.priexRoute === 'teachPlanCourse' || _this.isCourse==1;

          }
        },


        {name:'form-item',props:{prop:'course_list_id',label:'选择条目',required:true,limit:1, type:'data',showKey:'name',
            dialog:{
              width:'60%',
              url:'/xapi/course.course/listList',
              params:{},
              where:{fields:{id:'o_id',course_name:'course_name',unit_name:'unit_name',name:'name',create_time:'create_time'}},
            },
            whereColumns:[
              {name:'form-item',props:{prop:'keyword',label:'关键字',placeholder:'请输入条目名称',style:{width:'550px'}}},
            ],
            columns:[
              {prop:'id',label:'编号',width:'80' , align:'center',fixed:'left'},
              {prop:'course_name',label:blongTitle,minWidth:'220px' , align:'left'},
              {prop:'unit_name',label:'章节',minWidth:'220px' , align:'left'},
              {prop:'name',label:'条目',minWidth:'220px' , align:'left'},
              {prop:'create_date',label:'创建时间',width:'160' , align:'left'},
            ],
          },callback(c){
            c.props.dialog.params.is_plan = _this.priexRoute === 'teachPlanCourse'?1:0;
            c.props.dialog.params.course_id = _this.formData.course_id;
            // c.props.required = _this.formData.id > 0?false:true;
            c.hidden = _this.formData.course_id>0?false:true;
            
          }
        },
        {name:'form-item',props:{prop:'list_id',label:'选择题单',limit:1,disabled:true, type:'data',
            dialog:{
              width:'60%',
              // url:'/xapi/question.quest_list/list',
              url:'/xapi/question.question_list/list',
            },
            whereColumns:[
              {name:'form-item',props:{prop:'keyword',label:'题单名称',style:{width:'550px'}}},
            ],
            columns:[
              {prop:'id',label:'编号',width:'80' , align:'center'},
              {prop:'title',label:'<span><font style="color:red;">来源</font> - <font style="color:green;">创建人</font> - 题单名称</span>',template:(scope)=>{
                  return '<font style="color:red;">'+(scope.row.fromStr||'')+'</font> - <font style="color:green;">'+scope.row.user_name+'</font> - '+this.$jquery("<font>"+scope.row.title+"</font>").text();
                },minWidth:'220px' , align:'left'},
              {prop:'time_str',label:'时间',width:'120' , align:'center'},
              {prop:'diffStr',label:'难度',width:'120' , align:'center'},
              {prop:'create_date',label:'创建时间',width:'160' , align:'center'},
            ],
          }
          ,hidden(){
            return (_this.formData.id || 0) > 0;
          }
        },
        {name:'form-item',props:{prop:'p_title',label:'题单名称',disabled:true,},
         callback(c){
            c.hidden = !_this.flag;
          }},

        {name:'form-item',props:{prop:'title',label:'作业名称',required:true}},
        {name:'el-row' ,components:[
            {name:'el-col',props:{span:12},components:[
                {name:'form-item',props:{prop:'match_id',label:'作业类型', type:'select',data:{ url:"/api/v1_0_0.question_other/options/key/match/type/3",isAll:false }}},
              ]},
            {name:'el-col',props:{span:12},components:[
                {name:'form-item',props:{prop:'diff_id',label:'难度', type:'select',data: {url:'/xapi/question.question/getDiffList', labelOptions:{value:'id',label:'title'}}}},
              ]},
          ]
        },
       
        {name:'el-row' ,components:[
            {name:'el-col',props:{span:12},components:[
                {name:'form-item',props:{required:true,prop:'duration',label:'时长限制'},
                  components:{name:'el-row' ,components:[
                      {name:'el-col',props:{span:11},components:[
                          {name:'form-item',props:{prop:'duration_hour' , type:'number',options:{labelWidth:'0px'}},slots:{after:"小时"}},
                        ]},
                      {name:'el-col',props:{span:11,offset:2},components:[
                          {name:'form-item',props:{prop:'duration_minute' , type:'number',options:{labelWidth:'0px'}},slots:{after:"分钟"}},
                        ]},
                    ]
                  }
                },
              ]},
            {name:'el-col',props:{span:12},components:[
                {name:'form-item',props:{prop:'province_id',label:'省份', type:'select' , data:{ name:"question_other", keys:"province_id" , isAll:false }}},
              ]},
          ]
        },
         {name:'el-row' ,components:[
            {name:'el-col',props:{span:8},components:[
                {name:'form-item',props:{prop:'start_date',label:'开始时间', type:'datetime',maxTime(){
                      return _this.formData.end_date || ''
                    }
                  }}
              ]},
            {name:'el-col',props:{span:8},components:[
                {name:'form-item',props:{prop:'end_date',label:'结束时间', type:'datetime',minTime(){
                      return _this.formData.start_date || ''
                    }
                  }}
              ]},
            {name:'el-col',props:{span:8},components:[
              {name:'form-item',props:{prop:'time_str',label:'时间', type:'year'},slots:{after:"年"}},

              ]},

          ],callback(c){
            c.hidden = _this.priexRoute === 'teachPlanCourse';
          }},
        {name:'form-item',props:{prop:'content',label:'作业介绍' , type:'editor',style:{position:'relative',zIndex:0}}},
        // {name:'form-item',props:{prop:'know_ids',label:'知识点', type:'checkbox',data: {url:'/xapi/question.question/getKnowList', labelOptions:{value:'id',label:'title'}}}},
        // {name:'el-row' ,components:[
        //     {name:'el-col',props:{span:12},components:[
        //         {name:'form-item',props:{prop:'recommend',label:'是否推荐', type:'radio',data:[{label:'不推荐',value:0},{label:'推荐',value:1}]}},
        //       ]},
        //     {name:'el-col',props:{span:12},components:[
        //         {name:'form-item',props:{prop:'valid',label:'是否有效', type:'radio',data:[{label:'有效',value:1},{label:'无效',value:0}]}},
        //       ]}
        //   ]
        // },
        {name:'form-item',props:{prop:'sort',label:'排序权重',type:'number',min:0}},
      ],
      isOnlyField:false,
      isCourse:0,
      isPlan:0
    }
  },
  watch:{
    userInfo:{
      immediate:true,
      deep:true,
      handler(v,o){
        if(!this.$tool.obj_equal(v,o)){
          this.formData.user_id = (v || {}).id
        }
      },
    }
  },
  computed:{
    currentPower(){
      var power = (this.priexRoute || 'course') + (this.other_id && this.other_id !== '0' ?'TaskEdit' : 'TaskCreate')
      return this.$isPowers(power);
    }
  },
  
  beforeDestroy(){
    console.log("likiaaaaaaaaa");
    localStorage.setItem('isCourse',2)
    localStorage.setItem('isPlan',2)
  },
  methods:{
    routeUpdate(){
      console.log("进入添加作业");
      this.getDetail();
      console.log(this.priexRoute,'_this.priexRoute');
      this.isCourse=localStorage.getItem('isCourse')
      this.isPlan=localStorage.getItem('isPlan')
    },
    handleFormUpdate(){
      if(this.formData.duration_hour || this.formData.duration_minute){
        this.formData.duration_hour = this.formData.duration_hour || 0;
        this.formData.duration_minute = this.formData.duration_minute || 0;
        this.formData.duration = parseInt(this.formData.duration_hour) * 60 * 60 + parseInt(this.formData.duration_minute) * 60;
      }else{
        this.formData.duration = null;
      }
    },
    getDetail(){
      if(this.$route.params.other_id && this.$route.params.other_id !== '0'){
        this.loading = true;
        this.$http.post('/xapi/question.other/detail' ,{id:this.$route.params.other_id}).then(({data , isOnlyField})=>{
          this.formData = data || {};
          // this.formData.course_id=799
          // this.formData.list_id= Number(this.formData.pid) 
          this.flag=true

          console.log(this.formData,"this.formDatathis.formData");
          this.isOnlyField = isOnlyField === true;
        }).finally(()=>{
          this.loading = false;
        });
      }else{
        this.formData = {user_id:this.userInfo.id || 0,...this.$route.query,...this.$route.params};
      }
    },
    submitForm(){
      this.$refs.form.validate((_form,valid , errors)=>{
        if(valid){
          this.loading = true;
          // this.$http.post('/xapi/question.other/taskSave' , {...this.$route.params ,..._form}).then(({msg})=>{
          this.$http.post('/xapi/question.question_other/taskSave' , {...this.$route.params ,..._form}).then(({msg})=>{
            this.$handleMessage(msg , 'success',()=>{
              if(this.$route.name.indexOf('teachingCourse') === 0){
                this.$handleRoute('teachingCourseTask')
              }else if(this.$route.name.indexOf('teachPlanCourse') === 0){
                this.$handleRoute('teachPlanCourseTask')
              }else{
                this.$handleRoute('/match/myExamList')
              }
            });
          }).finally(()=>{
            this.loading = false;
          });
        }else if(errors.length > 0){
          this.$handleMessage(errors[0].message , 'error');
        }
      } , this.isOnlyField)
    },
  }
}
</script>

<style scoped>

</style>